import React, { Component } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe"

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
    

    let email;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value
      });
  
    return (
      <div id="newsletter">
        {status === "sending" && <div className="lightText">Verzenden...</div>}
        {status === "error" && (
          <div
            className="lightText"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            className="lightText"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}

        <input
          ref={node => (email = node)}
          type="email"
          placeholder="Meld je hier aan voor de nieuwsbrief"
        />

        <button onClick={submit}>
          Verstuur
        </button>
      </div>
    );
  };
 
 
class Newsletter extends Component {
    render() {
        const url = "https://como-no.us13.list-manage.com/subscribe/post?u=28747de7219fb7b46e2f3cba3&id=d591cb1f4d";
        return (
            <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
              />
            )}
          />
        )

    }
}

export default Newsletter;
