import React from "react";
import { Link } from "gatsby"

const Logo = (props) => (
    <Link to="/">
    <svg className="darkSvg" width="72px" height="66px" viewBox="0 0 72 66" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-70.000000, -111.000000)" className={props.white ? 'whiteFillSvg colorTransition' : 'darkRedFillSvg colorTransition'}>
                <g transform="translate(70.000000, 111.000000)">
                    <path d="M9.425,15.325 C11.275,15.325 12.925,14.425 13.925,13 L16.7,15.35 C14.975,17.4 12.375,18.7 9.425,18.7 C4.3,18.7 0.125,14.675 0.125,9.7 C0.125,4.725 4.3,0.675 9.425,0.675 C12.375,0.675 14.975,2 16.7,4.05 L13.925,6.4 C12.925,4.975 11.275,4.075 9.425,4.075 C6.35,4.075 3.825,6.325 3.825,9.7 C3.825,13.025 6.35,15.325 9.425,15.325 Z M32.425,0.7 C37.625,0.7 41.8,4.725 41.8,9.7 C41.8,14.675 37.625,18.7 32.425,18.7 C27.25,18.7 23.05,14.675 23.05,9.7 C23.05,4.725 27.25,0.7 32.425,0.7 Z M32.425,15.35 C35.575,15.35 38.15,12.825 38.15,9.7 C38.15,6.6 35.575,4.05 32.425,4.05 C29.275,4.05 26.725,6.6 26.725,9.7 C26.725,12.825 29.275,15.35 32.425,15.35 Z M43.075,24.375 L44.825,24.375 L44.825,42 L41.225,42 L41.225,32.25 L35.775,39.9 L30.3,32.25 L30.3,42 L26.725,42 L26.725,24.375 L28.475,24.375 L35.775,34.475 L43.075,24.375 Z M62.025,24.2 C67.225,24.2 71.4,28.225 71.4,33.2 C71.4,38.175 67.225,42.2 62.025,42.2 C56.85,42.2 52.65,38.175 52.65,33.2 C52.65,28.225 56.85,24.2 62.025,24.2 Z M62.025,38.85 C65.175,38.85 67.75,36.325 67.75,33.2 C67.75,30.1 65.175,27.55 62.025,27.55 C58.875,27.55 56.325,30.1 56.325,33.2 C56.325,36.325 58.875,38.85 62.025,38.85 Z M13.075,47.875 L16.675,47.875 L16.675,65.5 L14.925,65.5 L4.4,54.4 L4.4,65.5 L0.825,65.5 L0.825,47.875 L2.575,47.875 L13.075,58.975 L13.075,47.875 Z M33.875,47.7 C39.075,47.7 43.25,51.725 43.25,56.7 C43.25,61.675 39.075,65.7 33.875,65.7 C28.7,65.7 24.5,61.675 24.5,56.7 C24.5,51.725 28.7,47.7 33.875,47.7 Z M33.875,62.35 C37.025,62.35 39.6,59.825 39.6,56.7 C39.6,53.6 37.025,51.05 33.875,51.05 C30.725,51.05 28.175,53.6 28.175,56.7 C28.175,59.825 30.725,62.35 33.875,62.35 Z"></path>
                </g>
            </g>
        </g>
    </svg>
    </Link>
);

export default Logo;
