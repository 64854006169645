import React from "react";
import ContactContainer from "./Contactcontainer";

const Footer = (props) => (
    <div id="footer" className={props.white ? 'footerWhite' : 'footerBrown'}>
        <hr />
        <div id="footerRow">

            <ContactContainer white={props.white} />

            <div id="footerRight">
                <div id="footerIcons">
                    <a href="https://www.facebook.com/ComoNoOrganisatieOnderwijsOntwikkeling" target="_blank" rel="noopener noreferrer">
                            <svg width="33px" height="32px" viewBox="0 0 33 32" version="1.1">
                                <g id="Website" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <path d="M20.6748169,10.656 L19.1688169,10.657 C17.9878169,10.657 17.7588169,11.218 17.7588169,12.041 L17.7588169,13.857 L20.5758169,13.857 L20.2088169,16.702 L17.7588169,16.702 L17.7588169,24 L14.8218169,24 L14.8218169,16.702 L12.3658169,16.702 L12.3658169,13.857 L14.8218169,13.857 L14.8218169,11.76 C14.8218169,9.325 16.3088169,8 18.4798169,8 C19.5198169,8 20.4138169,8.077 20.6748169,8.112 L20.6748169,10.656 Z M16.5198169,0 C7.68381691,0 0.519816911,7.163 0.519816911,16 C0.519816911,24.836 7.68381691,32 16.5198169,32 C25.3568169,32 32.5198169,24.836 32.5198169,16 C32.5198169,7.163 25.3568169,0 16.5198169,0 L16.5198169,0 Z" id="Fill-183-Copy" className={props.white ? 'lightFillSvg' : 'whiteFillSvg'} ></path>
                                </g>
                            </svg>
                        </a>
                        {/*
                        <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                            <svg width="33px" height="32px" viewBox="0 0 33 32" version="1.1">
                                <g id="Website" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <path d="M22.8824169,12.7371 C22.8884169,12.8781 22.8924169,13.0191 22.8924169,13.1621 C22.8924169,17.4991 19.5904169,22.5011 13.5524169,22.5011 C11.6984169,22.5011 9.97341691,21.9581 8.52041691,21.0271 C8.77741691,21.0571 9.03841691,21.0721 9.30341691,21.0721 C10.8414169,21.0721 12.2564169,20.5481 13.3804169,19.6671 C11.9434169,19.6411 10.7314169,18.6921 10.3134169,17.3881 C10.5144169,17.4261 10.7194169,17.4461 10.9314169,17.4461 C11.2304169,17.4461 11.5204169,17.4061 11.7964169,17.3311 C10.2944169,17.0301 9.16341691,15.7031 9.16341691,14.1131 L9.16341691,14.0711 C9.60541691,14.3171 10.1124169,14.4651 10.6504169,14.4821 C9.76941691,13.8931 9.18941691,12.8881 9.18941691,11.7501 C9.18941691,11.1481 9.35141691,10.5841 9.63441691,10.0991 C11.2534169,12.0851 13.6724169,13.3921 16.4004169,13.5291 C16.3444169,13.2891 16.3154169,13.0381 16.3154169,12.7811 C16.3154169,10.9681 17.7854169,9.4981 19.5974169,9.4981 C20.5424169,9.4981 21.3954169,9.8971 21.9934169,10.5351 C22.7414169,10.3881 23.4444169,10.1151 24.0784169,9.7391 C23.8334169,10.5051 23.3124169,11.1481 22.6354169,11.5551 C23.2994169,11.4751 23.9314169,11.2991 24.5204169,11.0381 C24.0804169,11.6961 23.5234169,12.2741 22.8824169,12.7371 M16.5204169,0.000100000012 C7.68341691,0.000100000012 0.520416911,7.1631 0.520416911,16.0001 C0.520416911,24.8361 7.68341691,32.0001 16.5204169,32.0001 C25.3574169,32.0001 32.5204169,24.8361 32.5204169,16.0001 C32.5204169,7.1631 25.3574169,0.000100000012 16.5204169,0.000100000012" id="Fill-184-Copy" className={props.white ? 'lightFillSvg' : 'whiteFillSvg'} ></path>
                                </g>
                            </svg>
                        </a>
                        */}

                        <a href="https://nl.linkedin.com/in/jessicadejaeger" target="_blank" rel="noopener noreferrer">
                            <svg width="33px" height="32px" viewBox="0 0 33 32" version="1.1">
                                <g id="Website" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <path d="M24.5210169,23.9851 L21.2060169,23.9851 L21.2060169,18.7971 C21.2060169,17.5601 21.1840169,15.9691 19.4830169,15.9691 C17.7580169,15.9691 17.4950169,17.3171 17.4950169,18.7091 L17.4950169,23.9851 L14.1830169,23.9851 L14.1830169,13.3171 L17.3610169,13.3171 L17.3610169,14.7761 L17.4070169,14.7761 C17.8490169,13.9371 18.9310169,13.0521 20.5440169,13.0521 C23.9010169,13.0521 24.5210169,15.2611 24.5210169,18.1341 L24.5210169,23.9851 Z M10.4450169,11.8601 C9.38001691,11.8601 8.52101691,10.9981 8.52101691,9.9371 C8.52101691,8.8761 9.38001691,8.0141 10.4450169,8.0141 C11.5050169,8.0141 12.3660169,8.8761 12.3660169,9.9371 C12.3660169,10.9981 11.5050169,11.8601 10.4450169,11.8601 L10.4450169,11.8601 Z M12.1030169,23.9851 L8.78401691,23.9851 L8.78401691,13.3171 L12.1030169,13.3171 L12.1030169,23.9851 Z M16.5210169,0.000100000012 C7.68401691,0.000100000012 0.521016911,7.1631 0.521016911,16.0001 C0.521016911,24.8361 7.68401691,32.0001 16.5210169,32.0001 C25.3580169,32.0001 32.5210169,24.8361 32.5210169,16.0001 C32.5210169,7.1631 25.3580169,0.000100000012 16.5210169,0.000100000012 L16.5210169,0.000100000012 Z" id="Fill-209-Copy" className={props.white ? 'lightFillSvg' : 'whiteFillSvg'} ></path>
                                </g>
                            </svg>
                        </a>

                </div>
                <div id="footerDesign">
                    Design<br/><a href="http://www.remcovandun.nl" target="_blank" rel="noopener noreferrer">Remco van Dun</a>
                </div>
            </div>
        </div>

    </div>
);

export default Footer;
