import React, { Component } from "react";
import Newsletter from "./Newsletter";
import Logo from "./Logo";
import Hamburger from "./Hamburger";

import { Link } from "gatsby"
import ContactContainer from "./Contactcontainer";
/*

bartofzo@gmail.com
comonono
Comonoiscoming_1

*/


class Sidebar extends Component {
  constructor() {
    super();

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }


  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
      
    if (this.props.showMenu)
    {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.onClose();
        }
    }
  }

  render() {

  const menuStyle = this.props.showMenu ? { transform : 'none' } : null;

  return (

      <React.Fragment>


        <div ref={this.setWrapperRef} id="sidebar" style={menuStyle}>
            <div id="sidebarTop">
                <Logo />
                <div id="sideBarCloseButtonContainer">
                    <Hamburger close={true} onClick={this.props.onClose} />
                </div>
            </div>

            <div id="sidebarMiddle" className="menuMiddle">
                <nav role="navigation" className="menu menuMiddleContainer">
                    <ul>
                        <li className="sideBarMenuItemMargin">
                            <Link to="/organisatie" className="titleFontSize" onClick={this.props.onClose}>
                                Organisatie
                            </Link>
                        </li>
                        <li className="sideBarMenuItemMargin">
                            <Link to="/onderwijs" className="titleFontSize" onClick={this.props.onClose}>
                                Onderwijs
                            </Link>
                        </li>
                        <li className="sideBarMenuItemMargin">
                            <Link to="/ontwikkeling" className="titleFontSize" onClick={this.props.onClose}>
                                Ontwikkeling
                            </Link>
                        </li>

                    </ul>
                </nav>
            </div>
            <div id="sidebarBottom">
            
                <div id="sidebarNewsLetterContainer">
                    <Newsletter />
                </div>

                <ContactContainer white={true} />

            </div>
        </div>

    </React.Fragment>)
  }

}

export default Sidebar;
