import React from "react";


const ContactContainer = (props) => (

    <div className="contactContainer">

        <div className="contactSvg">
            <svg viewBox="0 0 69 115" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-74.000000, -5740.000000)" className={props.white ? 'lightSvg' : 'whiteSvg'} strokeWidth="2">
                        <g transform="translate(75.000000, 5741.000000)">
                            <path d="M58.7647541,48.9866325 C56.7390794,48.9866325 54.9929924,49.7153144 53.5264406,51.1727002 C52.0507229,52.639252 51.3128751,54.4036706 51.3128751,56.466009 C51.3128751,58.5283474 52.0507229,60.297349 53.5264406,61.7730667 C54.9929924,63.2396184 56.7619939,63.9728833 58.8334983,63.9728833 C60.8958368,63.9728833 62.6602555,63.2396184 64.1268072,61.7730667 C65.5841931,60.3065149 66.3128751,58.5375134 66.3128751,56.466009 C66.3128751,54.4128366 65.5841931,52.648418 64.1268072,51.1727002 C62.6785873,49.7153144 60.8912542,48.9866325 58.7647541,48.9866325 Z" id="Combined-Shape-Copy-17"></path>
                            <path d="M32.8626475,73.9709774 C30.8369728,73.9709774 29.0908858,74.6996594 27.624334,76.1570452 C26.1486162,77.6235969 25.4107684,79.3880156 25.4107684,81.450354 C25.4107684,83.5126924 26.1486162,85.2816939 27.624334,86.7574116 C29.0908858,88.2239634 30.8598873,88.9572283 32.9313917,88.9572283 C34.9937302,88.9572283 36.7581488,88.2239634 38.2247006,86.7574116 C39.6820865,85.2908599 40.4107684,83.5218583 40.4107684,81.450354 C40.4107684,79.3971815 39.6820865,77.6327629 38.2247006,76.1570452 C36.7764807,74.6996594 34.9891475,73.9709774 32.8626475,73.9709774 Z" id="Combined-Shape-Copy-18"></path>
                            <path d="M7.86264745,97.9709774 C5.8369728,97.9709774 4.09088576,98.6996594 2.62433398,100.157045 C1.14861624,101.623597 0.410768441,103.388016 0.410768441,105.450354 C0.410768441,107.512692 1.14861624,109.281694 2.62433398,110.757412 C4.09088576,112.223963 5.85988732,112.957228 7.93139172,112.957228 C9.99373017,112.957228 11.7581488,112.223963 13.2247006,110.757412 C14.6820865,109.29086 15.4107684,107.521858 15.4107684,105.450354 C15.4107684,103.397182 14.6820865,101.632763 13.2247006,100.157045 C11.7764807,98.6996594 9.98914754,97.9709774 7.86264745,97.9709774 Z" id="Combined-Shape-Copy-19"></path>
                            <path d="M32.8626475,24.0031531 C30.8369728,24.0031531 29.0908858,24.7318351 27.624334,26.1892209 C26.1486162,27.6557727 25.4107684,29.4201913 25.4107684,31.4825297 C25.4107684,33.5448681 26.1486162,35.3138696 27.624334,36.7895873 C29.0908858,38.2561391 30.8598873,38.989404 32.9313917,38.989404 C34.9937302,38.989404 36.7581488,38.2561391 38.2247006,36.7895873 C39.6820865,35.3230356 40.4107684,33.5540341 40.4107684,31.4825297 C40.4107684,29.4293572 39.6820865,27.6649386 38.2247006,26.1892209 C36.7764807,24.7318351 34.9891475,24.0031531 32.8626475,24.0031531 Z" id="Combined-Shape-Copy-26"></path>
                            <path d="M7.86264745,0.00315311028 C5.8369728,0.00315311028 4.09088576,0.731835086 2.62433398,2.1892209 C1.14861624,3.65577266 0.410768441,5.42019128 0.410768441,7.48252969 C0.410768441,9.5448681 1.14861624,11.3138696 2.62433398,12.7895873 C4.09088576,14.2561391 5.85988732,14.989404 7.93139172,14.989404 C9.99373017,14.989404 11.7581488,14.2561391 13.2247006,12.7895873 C14.6820865,11.3230356 15.4107684,9.55403405 15.4107684,7.48252969 C15.4107684,5.42935723 14.6820865,3.66493861 13.2247006,2.1892209 C11.7764807,0.731835086 9.98914754,0.00315311028 7.86264745,0.00315311028 Z" id="Combined-Shape-Copy-27"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>

        <div className={props.white ? 'contactInfo lightText' : 'contactInfo whiteText'}>
            <span>
                Jessica de Jaeger
            </span>
            <span>
                <a href="mailto:jessica@como-no.nl">jessica@como-no.nl</a>
            </span>
            <span>
                06-42111807
            </span>
        </div>



    </div>


);

export default ContactContainer;
