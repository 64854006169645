
import React, { Component } from "react";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

import '../styles/style.scss';


class Layout extends Component {
    constructor() {
        super();
  
        this.state = {
            showMenu : false,
            hideTopBar : false,
            waitForLeave : false
        };
    }

    componentWillReceiveProps(nextProps)
    {
        if (nextProps.introVideoStartedPlaying)
        {
            this.setState({showMenu : false, hideTopBar : true});
        }
        else
        {
            this.setState({hideTopBar : false});
        }
    }

    onHamburgerClick = (event) => {
        this.setState({ showMenu : true });
    }

    onHamburgerMouseEnter = (event) => {
        if (!this.state.waitForLeave)
        {
            this.setState({ showMenu : true });
        }
    }

    onHamburgerMouseLeave = (event) => {
        this.setState({ waitForLeave : false });
    }

    onClose = () =>
    {
        this.setState({ showMenu : false, waitForLeave : true });
    }

  
    render() {
        return (
            <div id="layout" className={this.props.exit ? 'exit' : null}>
                {this.props.children}
                <Topbar white={this.props.white} onHamburgerClick={this.onHamburgerClick} onHamburgerMouseLeave={this.onHamburgerMouseLeave} onHamburgerMouseEnter={this.onHamburgerMouseEnter} hide={this.state.hideTopBar}/>
                <Sidebar 
                    showMenu={this.state.showMenu}
                    onClose={this.onClose}
                    />
                <Footer white={this.props.white} />
            </div>);
    }
}
export default Layout;
  
