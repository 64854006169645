import React, { Component } from "react";

import Logo from "./Logo.js";
import Hamburger from "./Hamburger.js";

class Topbar extends Component {
  constructor() {
      super();

      this.state = {
          showTopBar : true,
          frontPage : true
      };

      this.prevScroll = 0;
      this.prevShow = true;
      this.prevFrontPage = true;
      this.frontTimeout = null;

  }

  componentWillReceiveProps(nextProps)
  {
    this.setState({showTopBar : !nextProps.hide });
  }

  componentDidMount()
  {
    this.prevScroll = window.scrollY;
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = (event) => {

   
    let front =  window.scrollY < 150;

    // Always visible when scrolled to top
    let show;

    // Only update scrolling when a treshold is exceeded
    if (Math.abs(window.scrollY - this.prevScroll) > 25)
    {
      show = window.scrollY < this.prevScroll || front;
      this.prevScroll = window.scrollY;
    }
    else
    {
      show = this.state.showTopBar || front;
    }
    

    if (front !== this.prevFrontPage || show !== this.prevShow)
    {
        this.setState({showTopBar : show });

        if (front)
        {
          if (this.frontTimeout)
          {
            clearTimeout(this.frontTimeout);
            this.frontTimeout = null;
          }

          this.setState({ frontPage : front });
        }
        else
        {
          if (this.frontTimeout)
          {
            clearTimeout(this.frontTimeout);
            this.frontTimeout = null;
          }

          this.frontTimeout = setTimeout(()=> this.setState({ frontPage : front }), 500);
        }

        this.prevFrontPage = front;
        this.prevShow = show;
    }
  }


  render() {

    let topBarClass =  this.state.showTopBar ? '' : 'hideTopBar';
    topBarClass += this.state.frontPage ? ' transparent' : '';
    // topBarClass += this.state.frontPage ? " noShrink" : " topBarShrink";

    const whiteText = this.props.white && this.state.frontPage;
    const whiteTextStyle = whiteText ? { color : 'white'} : null;

    const shrinkClass = this.state.frontPage ? "noShrink" : "shrink";
    const shrinkTextClass = this.state.frontPage ? "noShrink" : "shrinkText";

    return (
        

        <div id="topBar" className={topBarClass}>
            <div id="topBarInner">
              <div id="topBarLogoContainer" className={shrinkClass}>
                  <Logo white={whiteText} />
              </div>
              <div id="topBarTextContainer" className={shrinkTextClass}>
                  <span className="colorTransition" style={whiteTextStyle}>
                      Begeleider<br/>in wereld<br/>ideeën
                  </span>
              </div>

              <div id="siderbarToggle">
                  <Hamburger white={whiteText} onClick={this.props.onHamburgerClick} onMouseEnter={this.props.onHamburgerMouseEnter} onMouseLeave={this.props.onHamburgerMouseLeave} />
              </div>
            </div>
        </div>
        )
  }

}

export default Topbar;
