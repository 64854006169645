import React from "react";

const Hamburger = (props) => (
   
    props.close ? (
        <button onClick={props.onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} className="invisible">
        <svg width="24px" height="22px" viewBox="0 0 24 22" version="1.1">
            <g id="Website" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                <g id="3-copy-7" transform="translate(-1371.000000, -112.000000)" className="darkRedSvg" strokeWidth="2">
                    <g id="Group-10" transform="translate(1372.000000, 112.000000)">
                        <path d="M0.321250953,21.0085092 L21.2040354,0.974478444 L0.321250953,21.0085092 Z" id="Line-2-Copy"></path>
                        <path d="M0.69700235,0.99211815 L21.5797868,21.0261489 L0.69700235,0.99211815 Z" id="Line-2-Copy"></path>
                    </g>
                </g>
            </g>
        </svg>
        </button>) :
        (
            <button onClick={props.onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} className="invisible">
            <svg width="30px" height="22px" viewBox="0 0 30 22" version="1.1">
                <g id="Website" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="3-copy-7" transform="translate(-1368.000000, -112.000000)" className={props.white ? 'whiteSvg colorTransition' : "darkRedSvg colorTransition"} strokeWidth="2">
                        <g id="Group-4" transform="translate(1368.000000, 112.000000)">
                            <path d="M0.671001933,1.07993492 L29.6340718,1.07993492" id="Line-2"></path>
                            <path d="M0.671001933,11.0079675 L29.6340718,11.0079675" id="Line-2-Copy"></path>
                            <path d="M0.671001933,20.936 L29.6340718,20.936" id="Line-2-Copy-2"></path>
                        </g>
                    </g>
                </g>
            </svg>
            </button>
        )
);

export default Hamburger;
